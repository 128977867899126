import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { User } from '../../model/index.tsx';
import AvatarTitleCell from '../avatar-title-cell/view.tsx';

export type Props = {
	user: User | null;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick?: () => void | null | undefined;
};

export default function UserCell(props: Props) {
	const { user } = props;

	if (!user) {
		return null;
	}

	const { accountId, displayName, avatarUrl } = user;

	const profileUrl = fg('blu-4174-enghealth-11799-a11y-fix') ? undefined : user.profileUrl;
	const onClick = fg('blu-4174-enghealth-11799-a11y-fix') ? undefined : props.onClick;

	const content = (
		<AvatarTitleCell
			displayName={displayName}
			avatarUrl={avatarUrl}
			href={profileUrl}
			onClick={onClick}
		/>
	);

	return (
		<AsyncProfileCardNext accountId={accountId} TenantContextSubscriber={TenantContextSubscriber}>
			{content}
		</AsyncProfileCardNext>
	);
}

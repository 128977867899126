/**
 * @generated SignedSource<<b83eac22667553661ac783a54e8fd56e>>
 * @relayHash 6d24dfa35c6f395ab95946f45f8bdc25
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7a86b35e32cf33ef0f23d33e2097057a6e56cf5487698dca4c81d0a9bc33e986

import type { ConcreteRequest, Query } from 'relay-runtime';
export type createPlanButtonQuery$variables = {
  cloudId: string;
};
export type createPlanButtonQuery$data = {
  readonly jira: {
    readonly advancedRoadmapsNavigation: {
      readonly hasEditPermissions: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type createPlanButtonQuery = {
  response: createPlanButtonQuery$data;
  variables: createPlanButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "JiraAdvancedRoadmapsNavigation",
        "kind": "LinkedField",
        "name": "advancedRoadmapsNavigation",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "hasEditPermissions"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "createPlanButtonQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPlanButtonQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7a86b35e32cf33ef0f23d33e2097057a6e56cf5487698dca4c81d0a9bc33e986",
    "metadata": {},
    "name": "createPlanButtonQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3b932efbb41bcdb311409b16ae71301a";

export default node;

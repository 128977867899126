// initiate

export const INITIATE = 'state.actions.operation.INITIATE' as const;

export type InitiatePayload<TOperation extends string, TChildEntityId> = {
	id: string;
	operation: TOperation;
	childEntityId: TChildEntityId; // pass `void` as TChildEntityId if your page/modal does not use this property
};

export type InitiateAction<TOperation extends string, TChildEntityId> = {
	type: typeof INITIATE;
	payload: InitiatePayload<TOperation, TChildEntityId>;
};

export const initiate = <TOperation extends string, TChildEntityId>(
	payload: InitiatePayload<TOperation, TChildEntityId>,
): InitiateAction<TOperation, TChildEntityId> => ({
	type: INITIATE,
	payload,
});

// entity data retrieve failed

export const ENTITY_DATA_RETRIEVE_FAILED =
	'state.actions.operation.ENTITY_DATA_RETRIEVE_FAILED' as const;

export type EntityDataRetrieveFailedPayload<TOperation> = {
	id: string;
	operation: TOperation;
};

export type EntityDataRetrieveFailedAction<TOperation> = {
	type: typeof ENTITY_DATA_RETRIEVE_FAILED;
	payload: EntityDataRetrieveFailedPayload<TOperation>;
};

export const entityDataRetrieveFailed = <TOperation,>(
	payload: EntityDataRetrieveFailedPayload<TOperation>,
): EntityDataRetrieveFailedAction<TOperation> => ({
	type: ENTITY_DATA_RETRIEVE_FAILED,
	payload,
});

// dialog shown

export const DIALOG_SHOWN = 'state.actions.operation.DIALOG_SHOWN' as const;

export type DialogShownPayloadPayload<TOperation, TChildEntityId = string> = {
	id: string;
	operation: TOperation;
	childEntityId: TChildEntityId;
};

export type DialogShownPayloadAction<TOperation, TChildEntityId> = {
	type: typeof DIALOG_SHOWN;
	payload: DialogShownPayloadPayload<TOperation, TChildEntityId>;
};

export const dialogShown = <TOperation, TChildEntityId>(
	payload: DialogShownPayloadPayload<TOperation, TChildEntityId>,
): DialogShownPayloadAction<TOperation, TChildEntityId> => ({
	type: DIALOG_SHOWN,
	payload,
});

// entity updated

export const ENTITY_UPDATED = 'state.actions.operation.ENTITY_UPDATED' as const;

export type EntityUpdatedPayload<TOperation, TEntity> = {
	id: string;
	operation: TOperation;
	patch?: Partial<TEntity>;
};

export type EntityUpdatedAction<TOperation, TEntity> = {
	type: typeof ENTITY_UPDATED;
	payload: EntityUpdatedPayload<TOperation, TEntity>;
};

export const entityUpdated = <TOperation, TEntity>(
	payload: EntityUpdatedPayload<TOperation, TEntity>,
): EntityUpdatedAction<TOperation, TEntity> => ({
	type: ENTITY_UPDATED,
	payload,
});

// component update failed

export const ENTITY_UPDATE_FAILED = 'state.actions.operation.ENTITY_UPDATE_FAILED' as const;

export type EntityUpdateFailedPayload<TOperation> = {
	id: string;
	operation: TOperation;
};

export type EntityUpdateFailedAction<TOperation> = {
	type: typeof ENTITY_UPDATE_FAILED;
	payload: EntityUpdateFailedPayload<TOperation>;
};

export const entityUpdateFailed = <TOperation,>(
	payload: EntityUpdateFailedPayload<TOperation>,
): EntityUpdateFailedAction<TOperation> => ({
	type: ENTITY_UPDATE_FAILED,
	payload,
});

// cancelled

export const CANCELLED = 'state.actions.operation.CANCELLED' as const;

export type CancelledPayload<TOperation> = {
	id: string;
	operation: TOperation;
};

export type CancelledAction<TOperation> = {
	type: typeof CANCELLED;
	payload: CancelledPayload<TOperation>;
};

export const cancelled = <TOperation,>(
	payload: CancelledPayload<TOperation>,
): CancelledAction<TOperation> => ({
	type: CANCELLED,
	payload,
});

// actions

export type Action<TOperation, TChildEntityId, TEntity = undefined> =
	// @ts-expect-error - TS2344 - Type 'TOperation' does not satisfy the constraint 'string'.
	| InitiateAction<TOperation, TChildEntityId>
	| EntityDataRetrieveFailedAction<TOperation>
	| DialogShownPayloadAction<TOperation, TChildEntityId>
	| EntityUpdatedAction<TOperation, TEntity>
	| EntityUpdateFailedAction<TOperation>
	| CancelledAction<TOperation>;

import React, {
	createContext,
	useContext,
	type ReactNode,
	useState,
	useEffect,
	useCallback,
} from 'react';
import { useFavoritePlans } from '@atlassian/jira-favorites-store/src/index.tsx';
import { PLANS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import type { FavoriteChangeContextType } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { FavoriteChangeContext } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { PLAN_TYPE } from '@atlassian/jira-nav-item-service/src/constants.tsx';
import type { PlanItem } from '@atlassian/jira-nav-item-service/src/types.tsx';
import type { Plan, Query } from '../common/types.tsx';

type ContextType = {
	filter: string;
	isPlanFavorited: (arg1: Plan['id']) => boolean;
	isPlanPending: (id: string) => boolean;
	changeFavorite: (arg1: Plan['id'], arg2: boolean) => void;
};

type ProviderProps = {
	query: Query;
	children: ReactNode;
};

const Context = createContext<ContextType>({
	filter: '',
	isPlanFavorited: () => false,
	isPlanPending: () => false,
	changeFavorite: () => undefined,
});

const checkPendingStatus = (id: string, favoriteDelta: FavoriteChangeContextType): boolean => {
	const changedPlans = favoriteDelta.items.plans;

	if (!changedPlans || !changedPlans[id]) {
		return false;
	}

	return changedPlans[id].pending || false;
};

export const Provider = ({ children, query }: ProviderProps) => {
	const favoriteDelta = useContext(FavoriteChangeContext);
	const [existingFavoritedPlans] = useFavoritePlans();

	const [favoritePlans, setFavoritePlans] = useState<PlanItem[]>(
		existingFavoritedPlans.items || [],
	);

	useEffect(() => {
		if (!existingFavoritedPlans.isFetching) {
			setFavoritePlans(existingFavoritedPlans.items || []);
		}
	}, [existingFavoritedPlans]);

	const handleChange = useCallback(
		(id: number, next: boolean) => {
			favoriteDelta.changeFavorite({ id: String(id), type: PLANS_ITEM_TYPE, value: next });

			if (next === false) {
				const removedFavorite = favoritePlans.filter((plan) => plan.id !== id);
				setFavoritePlans(removedFavorite);
			} else {
				// title and url are not needed for the functionality of favouriting
				// but they are included in the PlanItems type
				// passing in title and url as empty strings so flow does not complain
				const addedFavorite = favoritePlans.concat([
					{ id, favourite: next, type: PLAN_TYPE, title: '', url: '' },
				]);
				setFavoritePlans(addedFavorite);
			}
		},
		[favoriteDelta, favoritePlans],
	);

	const context: ContextType = {
		filter: query.filter?.text || '',
		isPlanFavorited: (id) =>
			favoritePlans ? favoritePlans.filter((obj) => obj.id === id).length !== 0 : false,
		isPlanPending: (id) => checkPendingStatus(id, favoriteDelta),
		changeFavorite: handleChange,
	};

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const usePlans = () => useContext(Context);

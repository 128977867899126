import React from 'react';
import Spinner from '@atlaskit/spinner';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './common/types.tsx';

const LazyComponent = lazyForPaint(() =>
	import(/* webpackChunkName: "async-portfolio-3-plan-restore-trashed" */ './index').then(
		(module) => module.RestoreTrashedModal,
	),
);

const AsyncComponent = (props: Props) => (
	<JSErrorBoundary
		id="bundle-portfolio-3-plan-restore-trashed"
		fallback="flag"
		packageName="jira-plan-restore-trashed"
	>
		<Placeholder name="plan-restore-trashed" fallback={<Spinner size="small" />}>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncComponent;

import React from 'react';
import Button from '@atlaskit/button/standard-button';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type Props = {
	canAdd: boolean;
	caption: MessageDescriptor | undefined;
	onAdd: () => void;
};

function Add(props: Props) {
	const { canAdd, caption, onAdd } = props;
	const { formatMessage } = useIntl();
	return canAdd ? (
		<Button appearance="primary" onClick={onAdd}>
			{caption && formatMessage(caption)}
		</Button>
	) : null;
}

Add.defaultProps = {
	canAdd: false,
};
export default Add;

import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { connect } from '../../../common/remapped-redux/index.tsx';
import { initiate as initiateOperation } from '../../../state/actions/operation/index.tsx';
import type { State } from '../../../state/index.tsx';
import {
	isLoading,
	getOperationInitializationStatusPerEntity,
	getTableConfiguration,
	getSortField,
	getSortDirection,
	getOnReload,
	getEmptyFilterResultConfiguration,
	getSpotlightForCell,
	getTableMaskView,
	getDataProvider,
	getEntityIds,
	getFirstColumnHeadingAlignment,
	getLastColumnHeadingAlignment,
	getFilter,
	getTableLabel,
	getResultsAnnouncerMessage,
	getTotalItems,
} from '../../../state/selectors/index.tsx';
import { getTableMountedMetricKey } from '../../../state/selectors/metrics.tsx';
import DefaultEmptyView from './default-empty-view/index.tsx';
import type { Filter } from './types.tsx';
import Table from './view.tsx';

const getSpotlightForCellSelectorMemoized = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() =>
	createSelector(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) =>
			getSpotlightForCell(state),
		(res) => res,
	);

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter extends Filter,
	TChildEntityId,
>() => {
	const getSpotlightForCellSelectorInstance = getSpotlightForCellSelectorMemoized<
		TEntity,
		TOperation,
		TSortField,
		TFilter,
		TChildEntityId
	>();
	const onSortFactory = memoizeOne(
		// @ts-expect-error - TS7006 - Parameter 'sort' implicitly has an 'any' type.
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => (sort) =>
			getOnReload(state)({ page: 1, ...sort }),
	);
	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
		// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'EmptyFilterResultConfiguration'.
		const EmptyView = getEmptyFilterResultConfiguration(state).View || DefaultEmptyView;
		const TableMaskView = getTableMaskView(state);

		const filter = getFilter(state);
		const filterQuery = filter ? filter.query : '';

		return {
			tableConfiguration: getTableConfiguration(state),
			tableLabel: getTableLabel(state),
			resultsAnnouncerMessage: getResultsAnnouncerMessage(state),
			EmptyView,
			TableMaskView,
			dataProvider: getDataProvider(state),
			entityIds: getEntityIds(state),
			totalItems: getTotalItems(state),
			operationInitializationStatusPerEntity: getOperationInitializationStatusPerEntity(state),
			isLoading: isLoading(state),
			sortField: getSortField(state),
			sortDirection: getSortDirection(state),
			onSort: onSortFactory(state),
			mountMetricKey: getTableMountedMetricKey(state),
			getSpotlightForCell: getSpotlightForCellSelectorInstance(state),
			firstColumnHeadingAlignment: getFirstColumnHeadingAlignment(state),
			lastColumnHeadingAlignment: getLastColumnHeadingAlignment(state),
			filterQuery,
		};
	};
};

const mapDispatchToProps = {
	onOperationRequested: initiateOperation,
} as const;

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2.
export default connect(mapStateToPropsFactory, mapDispatchToProps)(Table);

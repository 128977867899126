import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';

const fireGasV2AndGasV3Analytics =
	(appNameSuffixForV2Event: string, appNameForV3OperationalEvent: string) =>
	(analyticsEvent: UIAnalyticsEvent) => {
		fireOperationalAnalytics(analyticsEvent, `${appNameForV3OperationalEvent} pageInitialised`);
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	appNameSuffixForV2Event,
	appNameForV3OperationalEvent,
}: {
	appNameSuffixForV2Event: string;
	appNameForV3OperationalEvent: string;
}) => (
	<ContextualAnalyticsData sourceType={SCREEN} sourceName="">
		<MountEvent
			onMount={fireGasV2AndGasV3Analytics(appNameSuffixForV2Event, appNameForV3OperationalEvent)}
		/>
	</ContextualAnalyticsData>
);

import {
	INITIATE,
	ENTITY_DATA_RETRIEVE_FAILED,
	DIALOG_SHOWN,
	ENTITY_UPDATED,
	ENTITY_UPDATE_FAILED,
	CANCELLED,
	type Action,
} from '../../actions/operation/index.tsx';
import type { OperationsState } from './types.tsx';

const reducer = <TOperation extends string, TChildEntityId>(
	state: OperationsState<TOperation, TChildEntityId> = [],
	action: Action<TOperation, TChildEntityId>,
): OperationsState<TOperation, TChildEntityId> => {
	switch (action.type) {
		case INITIATE:
			// adding new operation to array will render new <OperationModal>, showing new dialog/modal in UI
			return [
				...state,
				{
					...action.payload,
					isInitializing: true,
				},
			];

		case DIALOG_SHOWN:
			// change isInitializing from true to false
			return [
				...state.filter(
					({ id, operation }) => id !== action.payload.id || operation !== action.payload.operation,
				),
				{
					...action.payload,
					isInitializing: false,
				},
			];

		case ENTITY_DATA_RETRIEVE_FAILED:
		case ENTITY_UPDATED:
		case ENTITY_UPDATE_FAILED:
		case CANCELLED:
			return state.filter(
				({ id, operation }) => id !== action.payload.id || operation !== action.payload.operation,
			);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;

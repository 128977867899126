/* eslint-disable react/no-unstable-nested-components */
// eslint-disable-next-line jira/restricted/react-component-props
import React, { useState, useCallback, useEffect, type ComponentProps } from 'react';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import StarTitle from '@atlassian/jira-common-directory-v2/src/components/star-title-cell/view.tsx';
import Directory from '@atlassian/jira-directory-base/src/index.tsx';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import {
	DELETE,
	DUPLICATE,
	ARCHIVE,
	TRASH,
} from '@atlassian/jira-directory-base/src/model/operation-types.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useModifyPlansListForSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/services/index.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import PlanArchivingAsync from '@atlassian/jira-portfolio-3-plan-archiving/src/async.tsx';
import {
	ARCHIVE_ERROR_FLAG_ID,
	RESTORE_ERROR_FLAG_ID,
} from '@atlassian/jira-portfolio-3-plan-archiving/src/common/constants.tsx';
import PlanDuplicatingAsync from '@atlassian/jira-portfolio-3-plan-duplicating/src/async.tsx';
import { DUPLICATE_ERROR_FLAG_ID } from '@atlassian/jira-portfolio-3-plan-duplicating/src/common/constants.tsx';
import PlanRestoreTrashedAsync from '@atlassian/jira-portfolio-3-plan-restore-trashed/src/async.tsx';
import { RESTORE_TRASHED_ERROR_FLAG_ID } from '@atlassian/jira-portfolio-3-plan-restore-trashed/src/common/constants.tsx';
import PlanTrashAsync from '@atlassian/jira-portfolio-3-plan-trash/src/async.tsx';
import { TRASH_ERROR_FLAG_ID } from '@atlassian/jira-portfolio-3-plan-trash/src/common/constants.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import {
	ITEMS_PER_PAGE,
	PACKAGE_NAME,
	ERROR_REPORTING_TEAM,
	NO_JSW_ACCESS_FOR_USER,
	PLAN_DIRECTORY_ERROR_FLAG_ID,
} from '../common/constants.tsx';
import {
	type Plan,
	type TableConfiguration,
	type HeaderConfiguration,
	type DataProvider,
	type Query,
	TITLE,
	CREATOR,
	LEAD,
	FAVOURITE,
	LAST_COMMITTED_TIMESTAMP,
} from '../common/types.tsx';

import { Provider as PlansProvider } from '../controllers/index.tsx';
import { filterPlansByQuery } from '../services/index.tsx';
import ActionsCell from './content/cells/actions-cell/index.tsx';
import CreatorCell from './content/cells/creator-cell/index.tsx';
import FavoriteCell from './content/cells/favourite-cell/index.tsx';
import LastUpdatedCell, {
	Heading as LastUpdatedHeading,
} from './content/cells/last-updated-cell/index.tsx';
import LeadCell from './content/cells/lead-cell/index.tsx';
import PlanCell from './content/cells/plan-cell/index.tsx';
import CreateButton from './content/create-plan-button/index.tsx';
import DeleteModal from './content/delete-modal/index.tsx';
import EmptyScreen from './content/empty-screen/index.tsx';
import ErrorScreen from './content/error-screen/index.tsx';
import FilterView from './content/filter-view/index.tsx';
import messages from './messages.tsx';
import { useShowCreatePlansFromQueryParams } from './utils.tsx';

type Props = {
	locale: Locale;
	query: Query;
	onQueryChanged: (query: Query) => void;
};

const headerConfiguration: HeaderConfiguration = {
	caption: messages.headerCaption,
	ActionsView: CreateButton,
	FilterView: (props) => <FilterView {...props} />,
};

const tableConfigurationOld: TableConfiguration = [
	{
		title: StarTitle,
		Cell: FavoriteCell,
		width: 2.5,
		sortField: FAVOURITE,
	},
	{
		title: messages.planTitleHeading,
		Cell: PlanCell,
		sortField: TITLE,
		width: 54,
	},
	{
		title: messages.planCreatorHeading,
		Cell: CreatorCell,
		width: 20,
		sortField: CREATOR,
	},
	{
		title: LastUpdatedHeading,
		Cell: LastUpdatedCell,
		width: 20,
		sortField: LAST_COMMITTED_TIMESTAMP,
	},
	{
		title: messages.planMoreActionsHeading,
		Cell: ActionsCell,
		width: 6.5,
	},
];

const tableConfigurationNew: TableConfiguration = [
	{
		title: StarTitle,
		Cell: FavoriteCell,
		width: 2.5,
		sortField: FAVOURITE,
	},
	{
		title: messages.planTitleHeading,
		Cell: PlanCell,
		sortField: TITLE,
		width: 54,
	},
	{
		title: messages.planLeadHeading,
		Cell: LeadCell,
		width: 20,
		sortField: LEAD,
	},
	{
		title: LastUpdatedHeading,
		Cell: LastUpdatedCell,
		width: 20,
		sortField: LAST_COMMITTED_TIMESTAMP,
	},
	{
		title: messages.planMoreActionsHeading,
		Cell: ActionsCell,
		width: 6.5,
	},
];

export const usePlansByQuery = () => {
	const [plans, setPlans] = useState<Plan[] | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [errorCode, setErrorCode] = useState<string>();
	const [total, setTotal] = useState(0);

	const fetchData = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'query' implicitly has an 'any' type.
		async (query) => {
			setLoading(true);
			const { isError, result, errorCode: errorDetails } = await filterPlansByQuery(query);
			if (isError) {
				setError(true);
				setErrorCode(errorDetails);
				return;
			}
			if (result !== undefined) {
				const { values, total: totalFromQuery } = result;
				setPlans(values);
				setTotal(totalFromQuery);
				setLoading(false);
			}
		},
		[],
	);

	const getPlans = useCallback(() => (loading || plans == null ? [] : plans), [loading, plans]);
	const getTotal = useCallback(() => (loading ? 0 : total), [loading, total]);

	return { loading, error, errorCode, refreshQuery: fetchData, getPlans, getTotal };
};

const PlansDirectory = ({ locale, query: initialQuery, onQueryChanged }: Props) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const { addPlanInRelayStoreForSidebar, removePlanFromRelayStoreForSidebar } =
		useModifyPlansListForSidebar();

	const [query, setQuery] = useState(initialQuery);
	const [isPlanRestoringModalOpen, setIsPlanRestoringModalOpen] = useState<boolean>(false);
	const [restoringPlan, setRestoringPlan] = useState<Plan | undefined>(undefined);
	const [isPlanRestoringTrashedModalOpen, setIsPlanRestoringTrashedModalOpen] =
		useState<boolean>(false);
	const [restoringTrashedPlan, setRestoringTrashedPlan] = useState<Plan | undefined>(undefined);
	const { loading, error, errorCode, refreshQuery, getPlans, getTotal } = usePlansByQuery();

	useShowCreatePlansFromQueryParams();

	useEffect(() => {
		refreshQuery(query);
	}, [refreshQuery, query]);

	useEffect(() => {
		if (errorCode === NO_JSW_ACCESS_FOR_USER) {
			showFlag({
				id: PLAN_DIRECTORY_ERROR_FLAG_ID,
				type: 'error',
				title: messages.planDirectoryNoAccessPermissionTitle,
				description: messages.planDirectoryNoAccessPermissionDescriptionSpork,
			});
		}
	}, [errorCode, showFlag]);

	const dataProvider: DataProvider = {
		getData: getPlans,
		idSelector: (plan: Plan) => String(plan.id),
	};

	const emptyFilterResultConfiguration: {
		message: MessageDescriptor;
	} = {
		message: messages.emptyFilterResult,
	};

	const { page, filter, sortField, sortDirection } = query;

	const handleQuery = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'deltaQuery' implicitly has an 'any' type.
		(deltaQuery) => {
			setQuery((currQuery) => {
				const nextQuery = { ...currQuery, ...deltaQuery };
				// onQueryChanged simply returns the delta for the query rather than a full new Query obj
				// therefore need to combine with existing query to get a valid Query obj which can then
				// be successfully passed to the API to get new plans filtered by Query
				onQueryChanged(nextQuery);
				return nextQuery;
			});
		},
		[onQueryChanged],
	);

	const closePlanRestoringModal = (): void => {
		setIsPlanRestoringModalOpen(false);
		setRestoringPlan(undefined);
	};

	const onPlanRestoringComplete = (): void => {
		showFlag({
			type: 'success',
			title: messages.planRestoringFlagSuccess,
			testId: 'plan-unarchive-modal.ui.unarchive-modal.modal-flags.success-flag',
		});
		setIsPlanRestoringModalOpen(false);
		setRestoringPlan(undefined);
		refreshQuery(query);
		if (restoringPlan) {
			addPlanInRelayStoreForSidebar({ planId: restoringPlan.id });
		}
	};

	const onPlanRestoringError = (): void => {
		showFlag({
			id: RESTORE_ERROR_FLAG_ID,
			type: 'error',
			title: messages.planRestoringFlagErrorTitle,
			description: [
				messages.planRestoringFlagErrorDescription,
				{ planTitle: restoringPlan?.title || '' },
			],
			testId: 'plan-unarchive-modal.ui.unarchive-modal.modal-flags.restore-failure',
		});
		setIsPlanRestoringModalOpen(false);
		if (restoringPlan) {
			removePlanFromRelayStoreForSidebar({ planId: restoringPlan.id });
		}
	};

	const closePlanRestoringTrashedModal = (): void => {
		setIsPlanRestoringTrashedModalOpen(false);
		setRestoringTrashedPlan(undefined);
	};

	const onPlanRestoringTrashedComplete = (): void => {
		showFlag({
			type: 'success',
			title: messages.planRestoringFlagSuccess,
		});
		setIsPlanRestoringTrashedModalOpen(false);
		setRestoringTrashedPlan(undefined);
		refreshQuery(query);
		if (restoringTrashedPlan) {
			addPlanInRelayStoreForSidebar({ planId: restoringTrashedPlan.id });
		}
	};

	const onPlanRestoringTrashedError = (): void => {
		showFlag({
			id: RESTORE_TRASHED_ERROR_FLAG_ID,
			type: 'error',
			title: messages.planRestoringFlagErrorTitle,
			description: [
				messages.planRestoringFlagErrorDescription,
				{ planTitle: restoringPlan?.title || '' },
			],
		});
		setIsPlanRestoringTrashedModalOpen(false);
		if (restoringTrashedPlan) {
			removePlanFromRelayStoreForSidebar({ planId: restoringTrashedPlan.id });
		}
	};

	// This wrapper is created to allow sending custom parameters to the delete modal

	// Unstable components cause bugs and performance problems - please fix this! go/unstable-components
	const DeleteModalWrapper = (props: OperationModalProps<Plan>) => (
		<DeleteModal {...props} onDelete={() => refreshQuery(query)} plans={getPlans()} />
	);

	const DuplicateModalWrapper = (props: OperationModalProps<Plan>) => {
		const { entityId, onCancel } = props;
		const plans = getPlans();
		const planId = Number(entityId);
		const planTitle = plans.find((plan) => plan.id === planId)?.title || '';

		const onComplete = () => {
			showFlag({
				id: 'PLAN_DUPLICATING_SUCCESS',
				type: 'success',
				title: messages.planDuplicatedFlag,
			});

			refreshQuery(query);

			// onCancel closes the modal
			onCancel();
		};

		const onError = () => {
			showFlag({
				id: DUPLICATE_ERROR_FLAG_ID,
				type: 'error',
				title: messages.planDuplicatedFlagError,
			});

			// onCancel closes the modal
			onCancel();
		};

		return (
			<PlanDuplicatingAsync
				planId={planId}
				planTitle={planTitle}
				onClose={onCancel}
				onError={onError}
				onComplete={onComplete}
			/>
		);
	};

	const ArchiveModalWrapper = (props: OperationModalProps<Plan>) => {
		const { entityId, onCancel } = props;
		const plans = getPlans();
		const planId = Number(entityId);
		const currentPlan = plans.find((plan) => plan.id === planId);
		const planTitle = currentPlan?.title || '';
		const isAdmin = useIsAdmin();

		const getActions = useCallback(
			(flagId: string) => {
				if (!isAdmin) {
					return [];
				}
				return [
					{
						content: messages.archiveSuccessGoToArchiveButtonTitle,
						href: '/jira/plans/settings/archive',
					},
					{
						content: messages.archiveSuccessRestoreButtonTitle,
						onClick: async () => {
							setRestoringPlan(currentPlan);
							setIsPlanRestoringModalOpen(true);
							flagId && dismissFlag(flagId);
						},
					},
				];
			},
			[isAdmin, currentPlan],
		);

		const onComplete = () => {
			const flagId = toFlagId(`archive-${entityId}`);
			showFlag({
				id: flagId,
				type: 'success',
				title: messages.planArchivedFlag,
				isAutoDismiss: false,
				actions: getActions(flagId),
			});

			removePlanFromRelayStoreForSidebar({ planId });
			refreshQuery(query);

			// onCancel closes the modal
			onCancel();
		};

		const onError = () => {
			showFlag({
				id: ARCHIVE_ERROR_FLAG_ID,
				type: 'error',
				title: messages.planArchivedFlagErrorTitle,
				description: [messages.planArchivedFlagErrorDescription, { planTitle }],
				testId: 'plan-archive-modal.ui.archive-modal.modal-flags.archive-failure',
			});

			// onCancel closes the modal
			onCancel();
		};

		return (
			<PlanArchivingAsync
				planId={planId}
				planTitle={planTitle}
				isArchived={false}
				onClose={onCancel}
				onError={onError}
				onComplete={onComplete}
			/>
		);
	};

	const TrashModalWrapper = (props: OperationModalProps<Plan>) => {
		const { entityId, onCancel } = props;
		const plans = getPlans();
		const planId = Number(entityId);
		const currentPlan = plans.find((plan) => plan.id === planId);
		const planTitle = currentPlan?.title || '';
		const isAdmin = useIsAdmin();

		const getActions = useCallback(
			(flagId: string) => {
				if (!isAdmin) {
					return [];
				}
				return [
					{
						content: messages.trashSuccessGoToTrash,
						href: '/jira/plans/settings/trash',
					},
					{
						content: messages.trashSuccessRestorePlan,
						onClick: async () => {
							setRestoringTrashedPlan(currentPlan);
							setIsPlanRestoringTrashedModalOpen(true);
							flagId && dismissFlag(flagId);
						},
					},
				];
			},
			[isAdmin, currentPlan],
		);

		const onComplete = () => {
			const flagId = toFlagId(`trash-${entityId}`);
			showFlag({
				id: flagId,
				type: 'success',
				title: messages.planTrashSuccess,
				isAutoDismiss: true,
				actions: getActions(flagId),
			});

			removePlanFromRelayStoreForSidebar({ planId });
			refreshQuery(query);

			// onCancel closes the modal
			onCancel();
		};

		const onError = () => {
			showFlag({
				id: TRASH_ERROR_FLAG_ID,
				type: 'error',
				title: messages.planTrashErrorTitle,
				description: [messages.planTrashErrorDescription, { planTitle }],
			});

			// onCancel closes the modal
			onCancel();
		};

		return (
			<PlanTrashAsync
				planId={planId}
				onCancel={onCancel}
				onComplete={onComplete}
				onError={onError}
			/>
		);
	};

	const directoryProps: JSX.LibraryManagedAttributes<
		typeof Directory,
		ComponentProps<typeof Directory>
	> = {
		EmptyView: EmptyScreen,
		ErrorView: ErrorScreen,
		locale,
		operationModals: {
			[DELETE]: DeleteModalWrapper,
			[DUPLICATE]: DuplicateModalWrapper,
			[ARCHIVE]: ArchiveModalWrapper,
			[TRASH]: TrashModalWrapper,
		},
		// @ts-expect-error - TS2322 - Type '{ caption: MessageDescriptor; ActionsView: ComponentType<Record<any, any>>; FilterView: ComponentType<FilterViewProps<Filter>>; }' is not assignable to type 'HeaderConfiguration<unknown> | undefined'.
		headerConfiguration,
		// @ts-expect-error - TS2322 - Type 'TableConfiguration' is not assignable to type 'TableConfiguration<string, string, unknown> | TableConfigurationNext<string, string, unknown, unknown>'.
		tableConfiguration: fg('allow_users_to_change_plan_lead')
			? tableConfigurationNew
			: tableConfigurationOld,
		tableLabel: messages.tableAriaLabel,
		lastColumnHeadingAlignment: 'right',
		resultsAnnouncerMessage: messages.filterResultsAnnouncement,
		filter: filter?.text === '' ? undefined : filter,
		sortField,
		sortDirection,
		isLoading: loading,
		isError: error,
		page,
		itemsPerPage: ITEMS_PER_PAGE,
		totalItems: getTotal(),
		// @ts-expect-error - TS2322 - Type 'DataProvider' is not assignable to type 'DataProvider<unknown>'.
		dataProvider,
		emptyFilterResultConfiguration,
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onPatch: () => {},
		onReload: handleQuery,
	};

	return (
		<PlansProvider query={query}>
			<FireScreenAnalytics
				attributes={{ date: formatDateUTC(Date.now(), 'YYYY-MM-DD HH:mm:ss') }}
			/>
			<ReportErrors
				id={PACKAGE_NAME}
				packageName={PACKAGE_NAME}
				teamName={ERROR_REPORTING_TEAM}
				sendToPrivacyUnsafeSplunk
			>
				<Directory {...directoryProps} />
				{restoringPlan && isPlanRestoringModalOpen && (
					<PlanArchivingAsync
						isArchived
						planId={restoringPlan.id}
						planTitle={restoringPlan.title || ''}
						onClose={closePlanRestoringModal}
						onComplete={onPlanRestoringComplete}
						onError={onPlanRestoringError}
					/>
				)}
				{restoringTrashedPlan && isPlanRestoringTrashedModalOpen && (
					<PlanRestoreTrashedAsync
						planId={restoringTrashedPlan.id}
						planTitle={restoringTrashedPlan.title || ''}
						onClose={closePlanRestoringTrashedModal}
						onComplete={onPlanRestoringTrashedComplete}
						onError={onPlanRestoringTrashedError}
					/>
				)}
			</ReportErrors>
		</PlansProvider>
	);
};

export default PlansDirectory;

export const EDIT = 'EDIT' as const;
export const DELETE = 'DELETE' as const;
export const DUPLICATE = 'DUPLICATE' as const;
export const ARCHIVE = 'ARCHIVE' as const;
export const COPY = 'COPY' as const;
export const MOVE = 'MOVE' as const;
export const SHOW_DETAILS = 'SHOW_DETAILS' as const;
export const CHANGE_OWNER = 'CHANGE_OWNER' as const;
export const TRASH = 'TRASH' as const;
export const RESTORE = 'RESTORE' as const;

import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { EntityCellProps, Plan } from '../../../../common/types.tsx';
import messages from './messages.tsx';

const LastUpdatedCell = ({ entity }: EntityCellProps) => {
	const { formatDate } = useIntl();

	if (!entity) return null;

	const plan: Plan = entity;

	return plan.lastCommittedTimestamp ? (
		<TextCell>{formatDate(plan.lastCommittedTimestamp * 1000)}</TextCell>
	) : null;
};

export const Heading = () => {
	const { formatMessage } = useIntl();

	return <HeadingContainer>{formatMessage(messages.planLastUpdatedHeading)}</HeadingContainer>;
};

export default LastUpdatedCell;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TextCell = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'row',
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
});

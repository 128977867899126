import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { storeOptions } from '../../../common/remapped-redux/index.tsx';
import type { State } from '../../../state/index.tsx';
import { getPage, getTotalPages, getOnReload } from '../../../state/selectors/index.tsx';
import Paginator from './view.tsx';

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() => {
	const onPageChangeFactory = memoizeOne(
		// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type. | TS7006 - Parameter 'page' implicitly has an 'any' type.
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => (event, page) =>
			getOnReload(state)({ page }),
	);
	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => ({
		currentPage: getPage(state),
		totalPages: getTotalPages(state),
		onPageChange: onPageChangeFactory(state),
	});
};

export default connect(mapStateToPropsFactory, {}, null, storeOptions)(Paginator);

import React, { type ComponentType } from 'react';
import type { OperationModalProps } from '../../../model/index.tsx';

export type Props<TEntity> = {
	OperationModal: ComponentType<OperationModalProps<TEntity>>;
} & OperationModalProps<TEntity>;

export default function Operation<TEntity>(props: Props<TEntity>) {
	const { OperationModal, ...modalProps } = props;

	return <OperationModal {...modalProps} />;
}

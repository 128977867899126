import React from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Box, xcss } from '@atlaskit/primitives';
import {
	DUPLICATE,
	ARCHIVE,
	TRASH,
} from '@atlassian/jira-directory-base/src/model/operation-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { EntityCellProps } from '../../../../common/types.tsx';
import messages from './messages.tsx';

const ActionsCell = ({ onOperationRequested, entity }: EntityCellProps) => {
	const isReadOnly = entity?.readOnly || false;
	const { formatMessage } = useIntl();

	const handleRequestDuplicate = () => onOperationRequested(DUPLICATE, undefined);
	const handleRequestArchive = () => onOperationRequested(ARCHIVE, undefined);
	const handleRequestTrash = () => onOperationRequested(TRASH, undefined);

	if (isReadOnly) {
		return null;
	}

	const dropdownMenu = (
		<DropdownMenu
			placement="bottom-end"
			trigger={({ triggerRef, ...props }) => (
				<Button
					{...props}
					ref={triggerRef}
					appearance="subtle"
					aria-label={entity?.title || formatMessage(messages.untitledPlan)}
					iconBefore={<MoreIcon label="" />}
				/>
			)}
			shouldRenderToParent
		>
			<DropdownItemGroup>
				<DropdownItem onClick={handleRequestDuplicate}>
					{formatMessage(messages.duplicate)}
				</DropdownItem>
				<DropdownItem onClick={handleRequestTrash}>
					{formatMessage(messages.moveToTrash)}
				</DropdownItem>
				<DropdownItem onClick={handleRequestArchive}>
					{formatMessage(messages.archive)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);

	const testId = `plans-main.ui.content.cells.actions-cell.actions${entity ? `-${entity.id}` : ''}`;
	return (
		<Box xcss={containerStyles} testId={testId}>
			{dropdownMenu}
		</Box>
	);
};

export default ActionsCell;

const containerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingTop: 'space.050',
	paddingRight: 'space.025',
});

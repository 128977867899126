import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import { connect } from '../../../common/remapped-redux/index.tsx';
import {
	cancelled,
	entityDataRetrieveFailed,
	dialogShown,
	entityUpdated,
	entityUpdateFailed,
} from '../../../state/actions/operation/index.tsx';
import type { State, Action } from '../../../state/index.tsx';
import { getOperationModal, getOnPatch } from '../../../state/selectors/index.tsx';
import Operation from './view.tsx';

type Props<TOperation, TChildEntityId> = {
	isInitializing: boolean;
	id: string;
	operation: TOperation;
	childEntityId: TChildEntityId;
};

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>() => {
	const onDataRetrievedFactory = memoizeOne(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>, id: string) =>
			// @ts-expect-error - TS7031 - Binding element 'patch' implicitly has an 'any' type.
			({ patch }) =>
				getOnPatch(state)({ id, patch }),
	);
	return (
		state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
		ownProps: Props<TOperation, TChildEntityId>,
	) => {
		const { id, operation } = ownProps;
		return {
			OperationModal: getOperationModal(state, operation),
			onDataRetrieved: onDataRetrievedFactory(state, id),
		};
	};
};

const mapDispatchToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	dispatch: Dispatch<Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId>>,
	ownProps: Props<TOperation, TChildEntityId>,
) => {
	const { id, operation, childEntityId, isInitializing } = ownProps;

	return {
		entityId: id,
		isInitializing,
		onDialogShown: () => dispatch(dialogShown({ id, operation, childEntityId })),
		onDataRetrieveFailed: () => dispatch(entityDataRetrieveFailed({ id, operation })),
		// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
		onUpdated: ({ patch } = {}) => dispatch(entityUpdated({ id, operation, patch })),
		onUpdateFailed: () => dispatch(entityUpdateFailed({ id, operation })),
		onCancel: () => dispatch(cancelled({ id, operation })),
	};
};

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2. | TS2345 - Argument of type 'typeof Operation' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToPropsFactory, mapDispatchToProps)(Operation);

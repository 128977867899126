import type { State } from '../index.tsx';

export const getTableMountedMetricKey = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): string | undefined => state.app.props.metrics.tableMounted;

import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { storeOptions } from '../common/remapped-redux/index.tsx';
import Content from './content/index.tsx';
import Modals from './modals/index.tsx';
import DirectoryBase from './view.tsx';

export default connect(
	() => ({
		Content,
		Modals,
	}),
	null,
	null,
	storeOptions,
	// @ts-expect-error - TS2345 - Type 'any' is not assignable to type 'never'.
)(DirectoryBase);

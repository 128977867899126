import reduce from 'lodash/reduce';
import { string, number } from '@atlassian/jira-common-url-transform-presets/src/index.tsx';
import type { Query } from '@atlassian/jira-directory-base/src/model/index.tsx';
import type { SortFieldToParamTransformer, FilterToParamTransformer } from '../model/index.tsx';

export type ToUrlTransformer<TSortField, TFilter> = (
	arg1: Query<TSortField, TFilter> | undefined,
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

const toUrlTransformerFactory = <TSortField, TFilter>(
	sortFieldTransformer: SortFieldToParamTransformer<TSortField>,
	filterTransformer: FilterToParamTransformer<TFilter>,
): ToUrlTransformer<TSortField, TFilter> => {
	const valueTransformers = {
		// @ts-expect-error - TS7006 - Parameter 'page' implicitly has an 'any' type.
		page: (page) => ({ page: number.stringify(page) }),
		// @ts-expect-error - TS7006 - Parameter 'dir' implicitly has an 'any' type.
		sortDirection: (dir) => ({ sortOrder: string.stringify(dir) }),
		// @ts-expect-error - TS7006 - Parameter 'field' implicitly has an 'any' type.
		sortField: (field) => ({ sortKey: sortFieldTransformer(field) }),
		filter: filterTransformer,
	};

	return (query: undefined | Query<TSortField, TFilter>) =>
		reduce(
			query,
			(params, value, key: string) =>
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ page: (page: any) => { page: string; }; sortDirection: (dir: any) => { sortOrder: any; }; sortField: (field: any) => { sortKey: string; }; filter: FilterToParamTransformer<TFilter>; }'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ page: (page: any) => { page: string; }; sortDirection: (dir: any) => { sortOrder: any; }; sortField: (field: any) => { sortKey: string; }; filter: FilterToParamTransformer<TFilter>; }'.
				valueTransformers[key] ? { ...params, ...valueTransformers[key](value) } : params,
			{},
		);
};

export default toUrlTransformerFactory;

import React, { type ComponentType } from 'react';
import type { HeaderViewProps } from '../../../../model/index.tsx';

type Props<TFilter> = {
	HeaderView: ComponentType<HeaderViewProps<TFilter>>;
} & HeaderViewProps<TFilter>;

export default function Wrapper<TFilter>(props: Props<TFilter>) {
	const { HeaderView, hasEntities, onAdd, onFilter } = props;
	return <HeaderView hasEntities={hasEntities} onAdd={onAdd} onFilter={onFilter} />;
}

import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate } from 'relay-runtime';
import { JiraPlanAri } from '@atlassian/ari/jira/plan';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { updateRelayStore } from './utils.tsx';

export const useModifyPlansListForSidebar = () => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const activationId = useActivationId();

	const addPlanInRelayStoreForSidebar = useCallback(
		({ planId }: { planId: number }) => {
			commitLocalUpdate(environment, (store) => {
				const entityId = JiraPlanAri.create({
					siteId: cloudId,
					activationId,
					planId: String(planId),
				}).toString();

				updateRelayStore({ cloudId, store, entityId, action: 'ADD' });
			});
		},
		[cloudId, activationId, environment],
	);

	const removePlanFromRelayStoreForSidebar = useCallback(
		({ planId }: { planId: number }) => {
			commitLocalUpdate(environment, (store) => {
				const entityId = JiraPlanAri.create({
					siteId: cloudId,
					activationId,
					planId: String(planId),
				}).toString();

				updateRelayStore({ cloudId, store, entityId, action: 'REMOVE' });
			});
		},
		[cloudId, activationId, environment],
	);

	return { addPlanInRelayStoreForSidebar, removePlanFromRelayStoreForSidebar };
};

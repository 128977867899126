import React, { type ComponentType } from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
type Props = {
	hasEntities: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Filter: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Actions: ComponentType<Record<any, any>>;
	caption: MessageDescriptor | null;
	intl: IntlShape;
};

function Default(props: Props) {
	const {
		Filter,
		Actions,
		caption,
		hasEntities,
		intl: { formatMessage },
	} = props;

	let searchBar = null;
	let actions = null;

	if (hasEntities) {
		searchBar = <Filter />;
		actions = <Actions />;
	}

	return (
		<PageHeader truncateTitle actions={actions} bottomBar={searchBar}>
			{caption ? formatMessage(caption) : ''}
		</PageHeader>
	);
}

export default injectIntl(Default);

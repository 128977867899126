import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterResultAnnounce: {
		id: 'directory-base.content.table.filter-result-announce',
		defaultMessage:
			'{itemCount, plural, one {One item was found after filtering.} other {{itemCount} items were found after filtering.}}',
		description: 'message which say user how much projects were found after filtering',
	},
	filterResultsAnnouncement: {
		id: 'directory-base.content.table.filter-results-announcement',
		defaultMessage:
			'{itemCount, plural, =0 {No results were found that match your search} one {{itemCount} result found} other {{itemCount} results found}}',
		description:
			'Message for screen reader users to announce how many results were found after filtering',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	previousButton: {
		id: 'directory-base.content.paginator.previous-button',
		defaultMessage: 'Prev',
		description: 'Previous page button of pagination in a directory level page',
	},
	nextButton: {
		id: 'directory-base.content.paginator.next-button',
		defaultMessage: 'Next',
		description: 'Next page button of pagination in a directory level page',
	},
});

import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import TreeReporter from './common/page-ready/tree-reporter/index.tsx';
import { Provider } from './common/remapped-redux/index.tsx';
import type { Props } from './model/index.tsx';
import rootEpic from './ops/index.tsx';
import { setAppProps } from './state/actions/app/index.tsx';
import createStore, { type State, type Action } from './state/index.tsx';
import View from './view/index.tsx';

const defaultEntityIds: Array<never> = [];

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { GenericNoResults } from './assets';

// eslint-disable-next-line jira/react/no-class-components
export default class Directory<
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
> extends Component<Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>> {
	static defaultProps = {
		isOnboardingEnabled: false,
		headerConfiguration: undefined,
		FilterView: undefined,
		TableMaskView: undefined,
		CreateModal: undefined,
		metrics: {
			tableMounted: undefined,
		},
		entityIds: defaultEntityIds,
		dataProvider: undefined,
		renderOnboardingSpotlight: () => null,
		tableLabel: undefined,
		onboardingSpotlightTargets: [],
		onFirstRender: noop,
	};

	constructor(props: Props<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) {
		super(props);

		if (props.entityIds !== defaultEntityIds && props.dataProvider) {
			log.safeErrorWithoutCustomerData(
				'directory.base',
				'Please provide only one of props: [entityIds] or [dataProvider], dataProvider will be used',
			);
		}

		this.store = createStore<
			State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
			Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId>
		>(rootEpic);
		this.store.dispatch(setAppProps(props));
	}

	componentDidUpdate() {
		this.store.dispatch(setAppProps(this.props));
	}

	store: Store<State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>>;

	render() {
		return (
			<Provider store={this.store}>
				<TreeReporter onFirstMount={this.props.onFirstRender}>
					<View />
					<SpaStatePageReady />
				</TreeReporter>
			</Provider>
		);
	}
}

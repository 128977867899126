import React from 'react';
import UserCell from '@atlassian/jira-common-directory-v2/src/components/user-cell/index.tsx';
import type { EntityCellProps, Plan } from '../../../../common/types.tsx';
import { transformPlanUser } from '../../../../services/index.tsx';

const CreatorCell = ({ entity }: EntityCellProps) => {
	if (!entity) return null;

	const plan: Plan = entity;

	if (!plan.creator) {
		return null;
	}

	return <UserCell user={transformPlanUser(plan.creator)} />;
};

export default CreatorCell;

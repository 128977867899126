import React, { type ReactNode } from 'react';
import Spinner from '@atlaskit/spinner';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props as CommonProps } from './common/types.tsx';

const LazyComponent = lazyForPaint(() =>
	import(/* webpackChunkName: "async-portfolio-3-plan-trash" */ './index').then(
		(module) => module.TrashPlanModal,
	),
);

type Props = CommonProps & { fallback: ReactNode };

const AsyncComponent = ({ fallback, ...props }: Props) => (
	<JSErrorBoundary id="bundle-portfolio-3-plan-trash" fallback="flag" packageName="jira-plan-trash">
		<Placeholder
			name="plan-trash"
			fallback={fallback !== undefined ? fallback : <Spinner size="small" />}
		>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

AsyncComponent.defaultProps = {
	fallback: undefined,
};

export default AsyncComponent;

import type { State } from '../../../state/index.tsx';
import {
	getDataProvider,
	isLoading,
	isError,
	getFilter,
	getPage,
	getEntityIds,
} from '../../../state/selectors/index.tsx';

export type Status = 'empty' | 'has-entities' | 'error';

export const getStatus = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): Status => {
	if (isError(state)) {
		return 'error';
	}

	if (isLoading(state)) {
		return 'has-entities';
	}

	const dataProvider = getDataProvider(state);

	const elementsCount = dataProvider ? dataProvider.getData().length : getEntityIds(state).length;

	return elementsCount === 0 && getFilter(state) === undefined && getPage(state) === 1
		? 'empty'
		: 'has-entities';
};

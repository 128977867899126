import { ASC } from '@atlassian/jira-common-constants/src/sort-directions.tsx';
import { LEAD, CREATOR, FAVOURITE, LAST_COMMITTED_TIMESTAMP, type Query, TITLE } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	TITLE,
	CREATOR,
	LEAD,
	LAST_COMMITTED_TIMESTAMP,
	FAVOURITE,
	ACTIVE_STATUS,
	ARCHIVED_STATUS,
} from './types';

export const ITEMS_PER_PAGE = 25;

export const SORT_FIELDS = {
	[TITLE]: 'TITLE',
	[CREATOR]: 'CREATOR',
	[LEAD]: 'LEAD',
	[LAST_COMMITTED_TIMESTAMP]: 'LAST_COMMITTED_TIMESTAMP',
	[FAVOURITE]: 'FAVOURITE',
} as const;

export const initialQuery: Query = {
	sortField: TITLE,
	sortDirection: ASC,
	page: 1,
	filter: { text: '' },
};

export const CREATE_PLAN = 'create-plan' as const;
export const CREATE_SAMPLE_PLAN = 'create-sample-plan' as const;

export const NO_JSW_ACCESS_FOR_USER = 'no-jira-software-access-for-user';
export const PLAN_DIRECTORY_ERROR_FLAG_ID = 'plan-directory-error';

export const PACKAGE_NAME = 'jiraPlansMain';
export const ERROR_REPORTING_TEAM = 'advanced-roadmaps';

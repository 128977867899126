import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { storeOptions } from '../../../common/remapped-redux/index.tsx';
import {
	entityCreated,
	entityCreateFailed,
	cancelled,
} from '../../../state/actions/create/index.tsx';
import type { State } from '../../../state/index.tsx';
import { getCreateModal } from '../../../state/selectors/index.tsx';
import Create from './view.tsx';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => {
	const CreateModal = getCreateModal(state);
	if (!CreateModal) {
		throw new Error('Invalid state - no Create Modal provided');
	}
	return {
		CreateModal,
	};
};

const mapDispatchToProps = {
	onCreated: entityCreated,
	onCreateFailed: entityCreateFailed,
	onCancel: cancelled,
} as const;

export default connect(mapStateToProps, mapDispatchToProps, null, storeOptions)(Create);

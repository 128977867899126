import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { storeOptions } from '../../../../common/remapped-redux/index.tsx';
import type { State } from '../../../../state/index.tsx';
import { getHeaderConfiguration } from '../../../../state/selectors/index.tsx';
import { getStatus } from '../../common/selectors.tsx';
import AddAction from './add/index.tsx';
import Filter from './filter/index.tsx';
import Default from './view.tsx';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => {
	const headerConfiguration = getHeaderConfiguration(state);

	// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'HeaderConfiguration<TFilter>'.
	if (!headerConfiguration || headerConfiguration.View) {
		throw new Error('Invalid state - Default Header view must not be mounted');
	}

	return {
		Filter,
		// @ts-expect-error - TS2339 - Property 'ActionsView' does not exist on type 'HeaderConfiguration<TFilter>'.
		Actions: headerConfiguration.ActionsView || AddAction,
		hasEntities: getStatus(state) === 'has-entities',
		// @ts-expect-error - TS2339 - Property 'caption' does not exist on type 'HeaderConfiguration<TFilter>'.
		caption: headerConfiguration.caption || null,
	};
};

// @ts-expect-error - TS2345 - Argument of type 'ComponentType<Diff<Record<any, any> | (Record<any, any> & { children?: ReactNode; }), InjectIntlVoidProps>>' is not assignable to parameter of type 'Component<DispatchProp<any> & { Filter: ComponentClass<Omit<DispatchProp<any>, "dispatch">> & { WrappedComponent: Component<DispatchProp<any>>; }; Actions: any; hasEntities: boolean; caption: any; }>'.
export default connect(mapStateToProps, null, null, storeOptions)(Default);

import React from 'react';
import { styled } from '@compiled/react';
import EmptyState from '@atlaskit/empty-state';
import Link from '@atlaskit/link';
import { token } from '@atlaskit/tokens';
import EmptyScreenImage from '@atlassian/jira-illustrations/src/ui/adg4/jira/heroes/components/graph-go-up-with-people/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import CreatePlanButton from '../create-plan-button/index.tsx';
import CreateSamplePlanButton from '../create-sample-plan-button/index.tsx';
import messages from './messages.tsx';

const EmptyScreen = () => {
	const { formatMessage } = useIntl();

	return (
		<Center>
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(EmptyScreenImage)}
				header={formatMessage(messages.noPlansYetHeader)}
				description={
					<>
						<Padded>{formatMessage(messages.getStartedMessage)}</Padded>
						<FormattedMessage
							{...messages.getStartedDescription}
							values={{
								link: (
									<Link
										href="https://support.atlassian.com/jira-software-cloud/docs/get-started-with-advanced-roadmaps/"
										target="_blank"
									>
										{formatMessage(messages.getStartedWithPlans)}
									</Link>
								),
							}}
						/>
					</>
				}
				primaryAction={
					<ButtonGroup>
						<CreateSamplePlanButton />
						<CreatePlanButton />
					</ButtonGroup>
				}
			/>
		</Center>
	);
};

export default EmptyScreen;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Center = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Padded = styled.p({
	paddingBottom: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
});

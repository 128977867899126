import React, { type ComponentType } from 'react';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
export type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Content: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Modals: ComponentType<any>;
};

export default function DirectoryBase(props: Props) {
	const { Content, Modals } = props;
	return (
		<>
			<Content />
			<Modals />
		</>
	);
}

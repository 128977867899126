import type { ComponentType } from 'react';
import memoizeOne from 'memoize-one';
import { connect } from '../../../common/remapped-redux/index.tsx';
import type { State } from '../../../state/index.tsx';
import { getFilterView, getOnReload } from '../../../state/selectors/index.tsx';
import { getStatus } from '../common/selectors.tsx';
import Filter from './view.tsx';

const mapStateToPropsFactory = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(): ((state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FilterView: ComponentType<any> | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFilter: (filter?: any) => any;
	hasEntities: boolean;
}) => {
	const onFilterFactory = memoizeOne(
		(state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) =>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(filter?: any) =>
				getOnReload(state)({ page: 1, filter }),
	);

	return (state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>) => ({
		FilterView: getFilterView(state),
		onFilter: onFilterFactory(state),
		hasEntities: getStatus(state) === 'has-entities',
	});
};

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2. | TS2345 - Argument of type '<T>(props: Props<T>) => false | Element' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToPropsFactory, {})(Filter);

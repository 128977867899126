import { combineReducers } from 'redux';
import app from './app/index.tsx';
import create from './create/index.tsx';
import operations from './operations/index.tsx';

export default combineReducers({
	app,
	create,
	operations,
});

import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { storeOptions } from '../../../../../common/remapped-redux/index.tsx';
import { initiate } from '../../../../../state/actions/create/index.tsx';
import type { State } from '../../../../../state/index.tsx';
import { getHeaderConfiguration } from '../../../../../state/selectors/index.tsx';
import Add from './view.tsx';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
) => {
	const headerConfiguration = getHeaderConfiguration(state);
	// @ts-expect-error - TS2339 - Property 'View' does not exist on type 'HeaderConfiguration<TFilter>'. | TS2339 - Property 'ActionsView' does not exist on type 'HeaderConfiguration<TFilter>'.
	if (headerConfiguration && !headerConfiguration.View && !headerConfiguration.ActionsView) {
		// @ts-expect-error - TS2339 - Property 'isAddAllowed' does not exist on type 'HeaderConfiguration<TFilter>'. | TS2339 - Property 'addButtonCaption' does not exist on type 'HeaderConfiguration<TFilter>'.
		const { isAddAllowed, addButtonCaption } = headerConfiguration;
		return {
			canAdd: isAddAllowed,
			caption: addButtonCaption,
		};
	}

	return {};
};

const mapDispatchToProps = {
	onAdd: initiate,
} as const;

export default connect(mapStateToProps, mapDispatchToProps, null, storeOptions)(Add);

import flow from 'lodash/flow';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import {
	validatePageNumber,
	validateSortDirection,
} from '@atlassian/jira-common-pagination/src/index.tsx';
import { number } from '@atlassian/jira-common-url-transform-presets/src/index.tsx';
import type { Query } from '@atlassian/jira-directory-base/src/model/index.tsx';
import type { ParamToSortFieldTransformer, ParamToFilterTransformer } from '../model/index.tsx';

type FromUrlTransformer<TSortField, TFilter> = (arg1: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}) => Partial<Query<TSortField, TFilter>>;

const fromUrlTransformerFactory = <TSortField, TFilter>(
	sortFieldTransformer: ParamToSortFieldTransformer<TSortField>,
	filterTransformer: ParamToFilterTransformer<TFilter>,
): FromUrlTransformer<TSortField, TFilter> => {
	const transformers = {
		// @ts-expect-error - TS7006 - Parameter 'page' implicitly has an 'any' type.
		page: (page) => (validatePageNumber(number.parse(page)) ? { page: number.parse(page) } : {}),
		// @ts-expect-error - TS7006 - Parameter 'order' implicitly has an 'any' type.
		sortOrder: (order) => (validateSortDirection(order) ? { sortDirection: order } : {}),
		// @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type.
		sortKey: (key) => (sortFieldTransformer(key) ? { sortField: sortFieldTransformer(key) } : {}),
	};

	return flow([
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(params: any) => mapValues(params, (p) => (Array.isArray(p) ? p[0] : p)),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(params: any) =>
			reduce(
				params,
				(query, value, key: string) =>
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ page: (page: any) => { page: number; } | { page?: undefined; }; sortOrder: (order: any) => { sortDirection: any; } | { sortDirection?: undefined; }; sortKey: (key: any) => { sortField: TSortField | null; } | { ...; }; }'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ page: (page: any) => { page: number; } | { page?: undefined; }; sortOrder: (order: any) => { sortDirection: any; } | { sortDirection?: undefined; }; sortKey: (key: any) => { sortField: TSortField | null; } | { ...; }; }'.
					transformers[key] ? { ...query, ...transformers[key](value) } : query,
				{ filter: filterTransformer(params) },
			),
	]);
};

export default fromUrlTransformerFactory;

import React, { type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import LinkCell from '@atlassian/jira-common-directory-v2/src/components/link-cell/view.tsx';
import { TransitionComponent } from '@atlassian/jira-common-directory-v2/src/components/transition-component/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import type { EntityCellProps } from '../../../../common/types.tsx';
import messages from './messages.tsx';

export type Props = EntityCellProps & {
	onClick: (arg1: MouseEvent<EventTarget>, arg2: AnalyticsEvent) => void;
};

const PlanCell = ({ entity, onClick }: Props) => {
	const href = entity ? `/jira/plans/${entity.id}` : '';
	const { formatMessage } = useIntl();
	const isReadOnly = entity?.readOnly || false;
	const planTitle = entity?.title || formatMessage(messages.untitledPlan);

	return (
		<ContextualAnalyticsData
			attributes={{ date: formatDateUTC(Date.now(), 'YYYY-MM-DD HH:mm:ss') }}
		>
			<Container data-plan={entity ? entity.id : ''}>
				<TransitionComponent href={href} onClick={onClick}>
					{() => <LinkCell href={href} caption={planTitle} onClick={onClick} />}
				</TransitionComponent>
				{isReadOnly && (
					<Box xcss={lozengeContainerStyles}>
						<Lozenge>{formatMessage(messages.readOnlyLozengeText)}</Lozenge>
					</Box>
				)}
			</Container>
		</ContextualAnalyticsData>
	);
};

export default flow(withFireUiAnalytics({ onClick: 'plan' }))(PlanCell);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	maxWidth: '100%',
});

const lozengeContainerStyles = xcss({
	paddingLeft: 'space.100',
});

import React from 'react';
import GenericQueryParamAdapter from '@atlassian/jira-common-query-param-adapter/src/index.tsx';
import type { Props } from './model/index.tsx';
import toUrlTransformerFactory from './transformers/query-to-url.tsx';
import fromUrlTransformerFactory from './transformers/url-to-query.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <TSortField, TFilter>({
	location,
	children,
	sortFieldFromParam,
	filterFromParams,
	sortFieldToParam,
	filterToParams,
	defaultQuery,
}: Props<TSortField, TFilter>) => (
	<GenericQueryParamAdapter
		location={location}
		queryFromUrl={fromUrlTransformerFactory(sortFieldFromParam, filterFromParams)}
		queryToUrl={toUrlTransformerFactory(sortFieldToParam, filterToParams)}
		defaultQuery={defaultQuery}
	>
		{children}
	</GenericQueryParamAdapter>
);

import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import type { Props as AdapterProps } from './model/index.tsx';

type Props<TQuery> = {
	search: string;
	// eslint-disable-next-line jira/react/handler-naming
	toQuery: (search: string) => TQuery;
	onChange: (
		query: TQuery,
		prevParams: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		},
	) => void;
	children: AdapterProps<TQuery>['children'];
};

type State<TQuery> = {
	query: TQuery;
	prevSearch: string;
};

// eslint-disable-next-line jira/react/no-class-components
export class AdapterWithState<TQuery> extends Component<Props<TQuery>, State<TQuery>> {
	static getDerivedStateFromProps<TQuery>(
		props: Props<TQuery>,
		state: State<TQuery>,
	): null | {
		prevSearch: string;
		query: TQuery;
	} {
		if (props.search !== state.prevSearch) {
			return {
				query: props.toQuery(props.search),
				prevSearch: props.search,
			};
		}
		return null;
	}

	state: State<TQuery> = {
		query: this.props.toQuery(this.props.search),
		prevSearch: this.props.search,
	};

	componentDidUpdate(prevProps: Props<TQuery>, prevState: State<TQuery>) {
		if (!isEqual(prevState.query, this.state.query)) {
			const prevParams = queryString.parse(prevState.prevSearch);
			this.props.onChange(this.state.query, prevParams);
		}
	}

	onQueryChanged: (arg1: TQuery) => void = (query: TQuery) => {
		this.setState({ query });
	};

	render() {
		return this.props.children({
			query: this.state.query,
			onQueryChanged: this.onQueryChanged,
		});
	}
}

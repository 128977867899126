// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentType, ComponentProps } from 'react';
import { connect } from '../../common/remapped-redux/index.tsx';
import type { State } from '../../state/index.tsx';
import { isCreateOpen, getOperations } from '../../state/selectors/index.tsx';
import CreateModal from './create/index.tsx';
import OperationModal from './operation/index.tsx';
import Modals from './view.tsx';

const mapStateToProps = <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: State<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): {
	isCreateModalShown: boolean;
	CreateModal: ComponentType<
		JSX.LibraryManagedAttributes<typeof CreateModal, ComponentProps<typeof CreateModal>>
	>;
	OperationModal: ComponentType<
		JSX.LibraryManagedAttributes<typeof OperationModal, ComponentProps<typeof OperationModal>>
	>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	operations: any;
} => ({
	isCreateModalShown: isCreateOpen(state),
	CreateModal,
	operations: getOperations(state),
	OperationModal,
});

// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2. | TS2345 - Argument of type 'typeof Modals' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
export default connect(mapStateToProps, {})(Modals);

import { SET_APP_PROPS, type SetAppPropsAction } from '../../actions/app/index.tsx';
import type { AppState } from './types.tsx';

type Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId> = SetAppPropsAction<
	TEntity,
	// @ts-expect-error - TS2344 - Type 'TOperation' does not satisfy the constraint 'string'.
	TOperation,
	TSortField,
	TFilter,
	TChildEntityId
>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <
	TEntity,
	TOperation extends string,
	TSortField extends string,
	TFilter,
	TChildEntityId,
>(
	state: AppState<TEntity, TOperation, TSortField, TFilter, TChildEntityId> = {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		props: undefined as any,
	},
	action: Action<TEntity, TOperation, TSortField, TFilter, TChildEntityId>,
): AppState<TEntity, TOperation, TSortField, TFilter, TChildEntityId> => {
	switch (action.type) {
		case SET_APP_PROPS: {
			return {
				...state,
				props: action.payload,
			};
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
